.scrollBtn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: 0.5s;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    background: linear-gradient(315deg, #ffbc00, #ff0058);
    z-index: 1;
}

.scrollBtn::before{
    content: "";
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    background: white;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
    filter: blur(30px);
    transition: 0.5s;
    background: linear-gradient(315deg, #ffbc00, #ff0058);

    }




.scrollBtn i::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.scrollBtn:hover i::before{
    top: -50px;
    left: 50px;
    width: 25px;
    height: 25px;
    opacity: 1;
    border-radius: 100%;
}


.scrollBtn i::after{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    animation-delay: -1s;
}

.scrollBtn:hover i::after{
    bottom: -50px;
    right: 50px;
    width: 25px;
    height: 25px;
    opacity: 1;
}
.scrollBtn i{
    position: relative;
    left: 0;

    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    backdrop-filter: blue(10px);
    z-index: 1;
    transform: 0.5s;
}

@keyframes animate {
    0%{
        transform: translateY(10px);
    }
    50%{
        transform: translateY(-10px);
    }
}