* {
    font-family: 'poppins', sans-serif;
}

:root{
    --bg-color: #081b29;
    --second-bg-color: #112e42;
    --text-color: #ededed;
    --main-color: #00abf0;
}

.maingBG{
    background-color: var(--bg-color);
    color: var(--text-color);
}

.Projects{
    min-height: 81vh;
}

.skills{
    font-weight: 600;
    font-size: 3.5rem;
}
.header{
    background-color: yellowgreen;
    background: transparent;
    position: relative;
}

.NavDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Logo{
    font-size: 2.5rem;
    color: var(--text-color);
    font-weight: 600;
}

.navfont{
    font-size: 1.7rem;
    color: var(--text-color);
    font-weight: 500;
    margin-left: .5rem;
    transition: .3s;
    margin-left: 30px;
    text-decoration: none;
}

.navfont:hover{
    color: var(--main-color);
    cursor: pointer;
}



.home{
padding: 20px;
min-height: 81vh;
}

.icons{
    height: 50px;
    margin-top: -30px;
    margin-left: 5px;
}
.contactmeDiv{
    position: absolute;
    top: 650px;
}

.home-content{
    max-width: 60rem;
    padding: 20px;
}

.frameworks{
    margin-top: 25px;
}

.ProjectBG
{
    background-color: var(--second-bg-color);
}

.home-content h1{
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.3;
}

.home-content .text-animate h3{
    font-size: 3.2rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: .7px var(--main-color);
    background-image: linear-gradient(var(--main-color), var(--main-color));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position: 0 0;
  
    animation: homeBgText 6s linear infinite; 
    animation-delay: 2s;
}

.home-content .text-animate h3::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    border-radius: 2px solid var(--main-color);
    animation: homeCursorText 6s linear infinite; 
    animation-delay: 2s;
}

.home-content p{
    font-size: 1.4rem;
    margin: 2rem 0 4rem;
}

.btn-div{
    position: relative;
    display: flex;
    width: 34rem;
    height: 5rem;
}

.btn-div .BTN{
    position: relative;
    width: 15rem;
    height: 100%;
    background-color: var(--main-color);
    color: var(--second-bg-color);
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    z-index: 1;
    overflow: hidden;
}
.btn-div .BTN::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--bg-color);
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: .1rem;
    z-index: -1;
    transition: .5s;
}

.BTN:hover{
    color: var(--text-color);
}

.btn-div .BTN:hover::before{
    width: 100%;
    color: var(--text-color);
}


.about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background: var(--second-bg-color);
    padding-bottom: 6rem;
    min-height: 100vh;
}

.projectPage{
    min-height: 100vh;
}

.heading{
    font-size: 3.5rem;
    font-weight: 700;
    margin-top: 2rem;
    text-align: center;
}

.about-img{
    position: relative;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-img img{
    width: 90%;
    height: 90%;
    border-radius: 100%;
    border: .2rem solid var(--main-color);
}

.about-imgc{
    position: relative;
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-imgc img{
    width: 90%;
    height: 90%;
    border-radius: 100%;
    border: .2rem solid var(--main-color);
}

span {
    color: var(--main-color);
}

.about-img .circle-spin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: .2rem solid var(--bg-color);
    border-bottom: .2rem solid var(--bg-color);
    border-left: .2rem solid var(--main-color);
    border-right: .2rem solid var(--main-color);
    animation: aboutSpinner 8s linear infinite;
    z-index: 0;
}

.about-me-pic{
    width: 550px;
    height: 750px;
    object-fit: contain;
}


.about-content h3{
    font-size: 3rem;
    padding: 10px;
}

.about-content h1{
    font-size: 4rem;
    padding: 10px;
}

.about-content p{
    font-size: 1.5rem;
    margin: 2rem 0 3rem;
    padding: 2rem;
}



@media screen and (max-width: 969px) {
    .home{
        margin-top: 30px;
    }
    .contactmeDiv{
        top: 550px;
    }
    .home-content{
        margin-top: 50px;
    }

}

@media screen and (max-width: 800px) {
    html{
        font-size: 75%;
    }
    .icons{
        height: 30px;
        margin-top: -17px;
    }
    .contactmeDiv{
        top: 420px;
    }
        .contactmeDiv h2{
       font-size: 30px;
    }
    #navbarScroll{
        position: absolute;
        top: 45px;
    }

    .about-me-pic{
        width:300px;
        height: 300px;
    }
.home-content{
    padding-top: 40px;
}

}

@media screen and (max-width: 400px) {
.home-content h1{
    font-size: 4rem;
    padding: 0;
}
.navfont{
    font-size: 1.4rem;
}

}



/* KEY FRAMES */

@keyframes homeBgText{
    0%,
    10%,
    100% {
        background-position: -60rem 0;
    }

    65%,
    100% {
        background-position: 0 0;
    }
}

@keyframes homeCursorText{
    0%,
    10%,
    100% {
        width: 0;
    }

    65%,
    75%,
    85% {
        width: 100%;
        opacity: 1;
    }
    75%,
    81%{
        opacity: 0;
    }
}

@keyframes aboutSpinner {
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

