.resume{
    cursor: pointer;
    color: rgb(109, 109, 245);
}
.resume:hover{
    color: rgb(84, 84, 197);
}
.resumeDoc{
    width: 100%;
    height: 750px;
    min-width: 300px;
    overflow: auto;
    z-index: 1;
}