.footer{
    min-height: 200px;
    background: var(--bg-color);
    color: aliceblue;
    text-decoration: none;
}

.Game
{
    width: 920px;
    height: 575px;
}

@media screen and (max-width: 768px) {
    .Game{
        width: 768px;
        height: 460px;
    }
    .footerText p{
        font-size: 16px;
    }
    .footerText h1{
        font-size: 25px;
    }
}

@media screen and (max-width: 425px) {
    .Game{
        width: 400px;
        height: 200px;
    }
    .footerText p{
        font-size: 16px;
    }
    .footerText h1{
        font-size: 25px;
    }
    
}