.CarouselDems
{
    min-height: 300px;

}

@media screen and (max-width: 740px) {
    .CarouselDems{
        min-height: 200px;
    }
    
}