.ContactMeSTicky{
    position:fixed;
    left: 96%;
    top: 50%;
    display: inline-block;
    width: 30px;
}

@media screen and (max-width: 1000px) {
.ContactMeSTicky{
    left:90%;
}
}