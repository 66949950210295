.cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
}

.cont .card-box {
    position: relative;
    width: 320px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 30px;
    transition: 0.5s;
}

.cont .card-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    background: white;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
}

.cont .card-box::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    background: white;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
    filter: blur(30px);
    transition: 0.5s;
}

.cont .card-box:hover::before,
.cont .card-box:hover::after {
    transform: skewX(0deg);
    left: 20px;
    width: calc(100% - 90px);
}

.cont .card-box:nth-child(1)::before,
.cont .card-box:nth-child(1)::after {
    background: linear-gradient(315deg, #ffbc00, #ff0058);
}

.cont .card-box:nth-child(2)::before,
.cont .card-box:nth-child(2)::after {
    background: linear-gradient(315deg, #03a9f4, #ff0058);
}

.cont .card-box:nth-child(3)::before,
.cont .card-box:nth-child(3)::after {
    background: linear-gradient(315deg, #4dff03, #00d0ff);
}



.cont .card-box span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    pointer-events: none;
}

.cont .card-box span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.cont .card-box:hover span::before {
    top: -50px;
    left: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
}


.cont .card-box span::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 8px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    animation-delay: -1s;
}

.cont .card-box:hover span::after {
    bottom: -50px;
    right: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
}

.cont .card-box .card-content {
    position: relative;
    left: 0;
    padding: 20px 40px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    backdrop-filter: blue(10px);
    z-index: 1;
    transform: 0.5s;
}

@keyframes animate {
    0% {
        transform: translateY(10px);
    }

    50% {
        transform: translateY(-10px);
    }
}

/* NEW CARD */

.cardbox {
    height: auto;
    width: fit-content;
    border: 3px solid var(--main-color);
    padding: 7px;
    margin-top: 10px;
    border-radius: 5px;

}

.CardContent {
    position: relative;
    height: 450px;
    width: 300px;
    border-radius: 2%;
    transition: 0.5s;
}

.face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.face1 {
    text-align: center;
    padding: 20px;
    color: #00d0ff;
    transition: 0.5s;
}

.face1 h1 {
    text-transform: uppercase;
    margin-top: 50px;
}

.face1 p {
    margin-top: 7px;
    text-align: center;
    padding: 0px, 30px;
    text-align: justify;
}

.face2 {
    height: 450px;
    width: 300px;
    transition: 0.5s;
}

.face2 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: 0.5s;
}

.CardContent:hover .face2 {
    width: 100%;
    height: 100px;
    transition: 0.5s;

}

.CardContent:hover .face2 img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

}

.face a {
    border: 1px solid black;
    background: linear-gradient(315deg, #181a18, #462bb5);
    height: 50px;
    width: 120px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 22px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.face a:hover {
    background-color: #00d0ff;
    color:beige;
    background: linear-gradient(315deg, #333733, #3502ff);

}

.CardContent:hover {
    background: linear-gradient(315deg, #081b29, #112e42);
    height: 600px;
    width: 300px;
}

.face .projecticons {
    margin-top: 20px;
    display: flex;
    justify-content: end;
}

.textalignment {
    text-align: center;
    position: absolute;
}

.my-center {
    display: flex;
    justify-content: center;
}

.absoluteIcons{
    position: absolute;
    top: 90%;
}

.absoluteIcon{
    position: absolute;
    top: 90%;
    left: 1%;
}

@media screen and (max-width: 766px) {

    .my-center {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {

    .face1 h1 {
        font-size: 35px;
    }

    .face1 h2 {
        font-size: 30px;
    }

    .face1 p {
        font-size: 16px;
    }

    .face1 a {
        font-size: 16px;
    }

    .abtText {
        font-size: 35px;
        font-weight: 700;
        margin-top: 15px;
    }

    .mobileIcon {
        size: larger;
    }
}